import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
class SwTracker extends PolymerElement {
  static get is() {
    return 'sw-tracker';
  }

  static get properties() {
    return {
      postBody: {
        type: Object,
        reflectToAttribute: false,
        computed: 'computedValue(asgnid)'
      },
      asgnid: {
        type: Number,
        value: function () {
          return 0;
        }
      },
      user: {
        type: String,
        reflectToAttribute: true
      },
      trackedAsgns: {
        type: Array,

        observer: 'setAsgn',
        value: function() {
          return [];
        }
      }
    };
  }

  ready() {
    super.ready();
    setTimeout(() => {
      if (window.location.port === '8000' || window.location.host.includes('sealworks')) {
        return;
      }
      let tempAsgn = sessionStorage.getItem(`sessions_tracked${this.user}`);
      // console.log("SwTracker -> ready -> tempAsgn", tempAsgn)
      if (tempAsgn && tempAsgn.length > 0){
        this.set('trackedAsgns', JSON.parse(tempAsgn));
      }
      // console.log("SwTracker -> ready -> this.trackedAsgns.includes(this.asgnid)", this.trackedAsgns.includes(this.asgnid))

      if (!this.trackedAsgns.includes(this.asgnid)) {
        this.callTracker();
      }
    });
  }

  setAsgn(newVal, oldVal){
    // console.log('setAsgn', newVal, oldVal);
    if(newVal.length > 0){
      sessionStorage.setItem(`sessions_tracked${this.user}`, JSON.stringify(newVal));
    }
  }

  callTracker(){
    return fetch('/track/', {
      method: 'POST',
      body: JSON.stringify(this.postBody),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin'
    }).then((response) => {
    // console.log("Hi : : SwTracker -> callTracker -> response", response)
      if (!response.ok) {
        throw new Error(`Code: ${response.status}:: ${response.statusText} `);
      }
      return response.json();
    }).then((data) => {
      this.handleResponse(data)
    }).catch((error) => {
      this.handleErrorResponse(error)
      console.error('There has been a problem with your fetch operation:', error);
    });
  }

  handleResponse(response) {
    this.trackedAsgns.push(this.asgnid);
    this.set('trackedAsgns', this.trackedAsgns.slice())
  }

  handleErrorResponse(response) {
    // console.log("Hi : : SwTracker -> handleErrorResponse -> response", response)
    console.error('Response Error', response);
  }

  computedValue(asgnid) {
    return {
      asgnid: asgnid
    };
  }
}
customElements.define(SwTracker.is, SwTracker);
