const $_documentContainer = document.createElement('template');

$_documentContainer.innerHTML = `<dom-module id="sw-styles">
  <template>
    <style>
      html{

        --paper-font-common-base: {
          font-family: 'Verdana', 'Helvetica', sans-serif;
          -webkit-font-smoothing: antialiased;
        };

        --paper-font-common-code: {
          font-family: 'Verdana', 'Helvetica', sans-serif;
          -webkit-font-smoothing: antialiased;
        };

        --ann-nav-red: rgb(218, 19, 24);
        --ann-nav-orange: rgb(240, 143, 44);
        --ann-nav-yellow: rgb(255, 255, 51);
        --ann-nav-green: rgb(118, 189, 72);
        --ann-nav-blue: rgb(91, 169, 247);
        --ann-nav-purple: rgb(176, 114, 224);
        --ann-nav-gray: rgb(153, 153, 153);
        --ann-nav-black: rgb(0, 0, 0);
        --ann-nav-white: rgb(255, 255, 255);
        --white: #fff;
        --black: #000;
        --text: #444;

        paper-dialog {
          max-width: 500px;
          /*padding: 2em;*/
        }

      }

      [hidden] {
        display: none;
      }

      button[error]:first-of-type{
        background-color: #d2211f;
      }
    </style>
  </template>
</dom-module>`;

document.head.appendChild($_documentContainer.content);
