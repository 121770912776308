import './sw-sanitize.js';
import { GlobalBase } from './global-base.js';

import { html } from '@polymer/polymer/polymer-element.js';
class DbqModal extends GlobalBase {
  static get is() {
    return 'dbq-modal';
  }
  static get properties() {
    return {
      hidden: {
        type: Boolean,
        notify: true,
        reflectToAttribute: true,
        value: function () {
          return true;
        }
      },
      promiseOpen: Object,
      confirm: {
        type: Boolean,
        reflectToAttribute: true,
        value: function () {
          return false;
        }
      },
      modal: {
        type: Boolean,
        reflectToAttribute: true,
        value: function () {
          return false;
        }
      },
      header: {
        type: String,
        value: function () {
          return null;
        }
      },
      webpSrc: {
        type: String,
        value: function () {
          return null;
        }
      },
      jpgSrc: {
        type: String,
        value: function () {
          return null;
        }
      },
      imgAlt: {
        type: String,
        value: ''
      },
      caption: {
        type: String,
        value: function () {
          return null;
        }
      },
      confirmed: {
        type: Boolean,
        notify: true,
        value: function () {
          return false;
        }
      },
      naked: {
        type: String,
        observer: 'setHasContent'
      },
      hasContent: {
        type: Boolean,
        value: false
      },
      top: {
        type: Boolean,
        reflectToAttribute: true
      },
      ref: {
        type: Object
      },
      bootDom: {
        type: String
      },

      content: {
        type: String,
        notify: true,
        observer: 'setHasContent'
      }
    };
  }

  static get template() {
    return html`
    <section maximize$="[[!hasContent]]" >
      <header>
        <h2 hidden$="[[!header]]">
          [[header]]
        </h2>
        <button aria-label="close" hidden$="[[!modal]]" on-click="close">
          <svg class="icon">
            <use class="icon-text" href="/static/svg/custom-icons.svg#times" />
          </svg>
        </button>
      </header>
      <main>
        <picture hidden$="[[hasContent]]">
          <source hidden$="[[hasContent]]" srcset$="[[webpSrc]]" src$="[[webpSrc]]" alt$="[[imgAlt]]"  type="image/webp">
          <img hidden$="[[hasContent]]" srcset$="[[jpgSrc]]" src$="[[jpgSrc]]" alt$="[[imgAlt]]" type="image/jpeg" loading="lazy" >
        </picture>
        <span hidden$="[[!hasContent]]" style="width: 100%;">
          <sw-sanitize html="[[content]]"></sw-sanitize>
        </span>
      </main>
      <footer hidden$="[[!confirm]]">
        <button class="uk-button uk-button-default" on-click="close">[[trans('general.close')]]</button>
        <button class="uk-button uk-button-primary" on-click="confirmAction">[[trans('general.ok')]]</button>
      </footer>
    </section>
    `;
  }

  ready() {
    this.bootDom = this.innerHTML;
    this.innerHTML = '';
    super.ready();


    window.addEventListener('keyup', (event) => {
      //ESCAPE key
      if (event.keyCode === 27 && (this.modal || this.confirm)) {
        this.close();
      }
      // ENTER key
      //&& this.confirm
      if (event.keyCode === 13  && !this.hidden) {
        this.confirmAction();
      }
    });
    setTimeout(()=>{
      this.contentFromContents()
    })
  }

  open() {
    this.hidden = false;
    this.promiseOpen = new Promise((resolve, reject) => {
      let hiddenChanged = () => {
        setTimeout(() => {
          return resolve(this.confirmed);
        });

        this.removeEventListener('hidden-changed', hiddenChanged.bind(this));
      };
      this.addEventListener('hidden-changed', hiddenChanged.bind(this));
    });


    return this.promiseOpen;
  }

  setHasContent(newValue, oldValue) {
    this.hasContent = !this.naked && this.content !== null && this.content !== undefined;
    this.contentFromContents();
  }

  contentFromContents(){
    // console.log("DbqModal -> contentFromContents --> !this.webpSrc && !this.jpgSrc && !this.content", !this.webpSrc, !this.jpgSrc, !this.content,this.content !== undefined,  this.webpSrc, this.jpgSrc, this.bootDom)

    if(!this.webpSrc && !this.jpgSrc && !this.content && this.content === undefined ){
      this.content = this.bootDom;
    }
  }

  close(event) {
    if(event){
      event.preventDefault();
    }
    this.hidden = true;
    this.confirmed = false;
    document
        .querySelector("video")?.pause();
  }

  confirmAction(event) {
    if (event){event.preventDefault();}
    this.confirmed = true;
    setTimeout(() => {
      this.confirmed = false;
    }, 1000);
    this.hidden = true;
  }

  _attachDom(dom) {
    this.appendChild(dom);
  }
}
customElements.define(DbqModal.is, DbqModal);
