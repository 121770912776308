import { PolymerElement } from '@polymer/polymer/polymer-element.js';

export class SwSanitize extends PolymerElement {
  static get is() {
    return 'sw-sanitize';
  }
  static get properties() {
    return {
      html: {
        type: String,
        observer: 'updateContent'
      },
      options: {
        type: Object,
        value: function () {
          return {
            whiteList: {
              div: ['id', 'class'],
              section: ['id', 'class']
            },
            onIgnoreTag: function (tag, html, options) {
              if (tag.substr(0, 5) === 'iron-') {
                // do not filter its attributes
                return html;
              }
              if (tag.substr(0, 3) === 'sw-') {
                // do not filter its attributes
                return html;
              }
              if (tag.substr(0, 4) === 'dbq-') {
                // do not filter its attributes
                return html;
              }
              if (tag === 'style') {
                // do not filter its attributes
                return html;
              }
            }
          };
        }
      }
    };
  }

  ready() {
    super.ready();
    setTimeout(() => {
      this.updateContent();
    });
  }

  updateContent() {
    this.innerHTML = this.html;
  }
}

window.customElements.define(SwSanitize.is, SwSanitize);
